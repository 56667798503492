<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    append-to-body
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>批量排课</span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px"
             style="max-height: 550px;overflow-y: scroll">
      <el-form-item label="开始日期：" prop="beginDate">
        <el-row>
          <el-date-picker
            v-model="formData.beginDate"
            style="float: left"
            type="date"
            format="yyyy-MM-dd"
            @change="startTimeChange"
            value-format="yyyy-MM-dd"
            placeholder="选择开始上课时间">
            >
          </el-date-picker>
        </el-row>
      </el-form-item>
      <el-form-item label="授课时间：">
        <el-row v-for="(value,parentIndex) in weekArray" :key="parentIndex" :class="parentIndex > 0 ? 'margintop10' : ''">
          <el-col :span='5'>
            <el-time-picker
              style="width: 100%"
              v-model="value.lessonTime"
              format="HH:mm"
              value-format="HH:mm"
              placeholder="选择授课时间">
            </el-time-picker>
          </el-col>
          <el-col :span='13' :offset="2">
            <div class="parentClass">
              <div
                :class="item.choose ? 'itemweekchoose' : 'itemweek'"
                v-for="(item,index) in value.weekOptions"
                :key="index"
                @click="weekClick(parentIndex,index)"
              >
                <div>{{ item.title }}</div>
              </div>
            </div>
          </el-col>
          <el-col :span='2' :offset="1">
            <i class="el-icon-circle-plus-outline plus" v-if="parentIndex === 0" @click="plusObject"></i>
            <i class="el-icon-remove-outline plus" v-else @click="removeObject(parentIndex)"></i>
          </el-col>
        </el-row>
      </el-form-item>
      <!--          <el-row style="float: left" v-if="formData.beginDate">-->
      <!--            <el-dropdown trigger="click" style="margin-left: 10px;"  v-if="commonTimeOptions.length !== 0" v-model="commonTime"  @command="commonTimeChange">-->
      <!--            <span class="el-dropdown-link">-->
      <!--              常用时间段<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
      <!--            </span>-->
      <!--              <el-dropdown-menu slot="dropdown">-->
      <!--                <el-dropdown-item :command="item"   v-for="item in commonTimeOptions">-->
      <!--                  <span style="float: left">{{ item.startTime }}~</span>-->
      <!--                  <span style="float: left">{{ item.endTime }}</span>-->
      <!--                  <span class="el-dropdown-link" style="float: right;margin-left: 15px" @click.stop="deleteCommonTime(item)">删除</span>-->
      <!--                </el-dropdown-item>-->
      <!--                <el-button style="margin: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>-->
      <!--              </el-dropdown-menu>-->
      <!--            </el-dropdown>-->
      <!--            <el-button v-else style="margin-left: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>-->
      <!--          </el-row>-->

      <!--      <el-form-item label="上课时长：" prop="lessonLong">-->
      <!--        <el-input-number style="width: 20%;" :precision="0" v-model="formData.lessonLong" :min="0" :controls="false" @change="timeDurationhandleChange"></el-input-number>-->
      <!--        <span style="margin-left: 10px;margin-right: 30px;">分钟</span>-->
      <!--        <el-dropdown trigger="click" style="margin-left: 10px;"   v-model="timeDurationSelect"  @command="timeDurationSelectChange">-->
      <!--            <span class="el-dropdown-link">-->
      <!--              常用时长<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
      <!--            </span>-->
      <!--          <el-dropdown-menu slot="dropdown">-->
      <!--            <el-dropdown-item :command="item.value"   v-for="item in timeDurationOptions">-->
      <!--              <span style="float: left">{{ item.label}}</span>-->
      <!--            </el-dropdown-item>-->
      <!--          </el-dropdown-menu>-->
      <!--        </el-dropdown>-->
      <!--      </el-form-item>-->
    </el-form>

    <el-row slot="footer">
      <el-button type="primary" @click="confirm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>

    <add-common-time :show="showCommomTime" @close="closeCommonTimeDialog"></add-common-time>
  </el-dialog>
</template>

<script>
import { addBat } from '@/api/merchantClass'
import addCommonTime from '@/components/merchantClass/add-commontime'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  components: {
    addCommonTime
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      nodeList: [{
        week: 'week1', times: '' }, {
        week: 'week2', times: '' }, {
        week: 'week3', times: '' }, {
        week: 'week4', times: '' }, {
        week: 'week5', times: '' }, {
        week: 'week6', times: '' }, {
        week: 'week7', times: '' }
      ],
      weekArray: [
        {
          lessonTime: '',
          weekOptions: [
            {
              title: '周一',
              choose: false,
            }, {
              title: '周二',
              choose: false,
            }, {
              title: '周三',
              choose: false,
            }, {
              title: '周四',
              choose: false,
            }, {
              title: '周五',
              choose: false,
            }, {
              title: '周六',
              choose: false,
            }, {
              title: '周日',
              choose: false,
            },
          ],
        }
      ],

      commonTimeOptions: [],
      showCommomTime: false,
      commonTime: '',
      formData: {
        hour: '00',
        minute: '00',
        weekSet: [],
      },
      rules: {
        beginDate: [
          { required: true, message: '开始日期不能为空', trigger: 'blur' }
        ],
        // lessonLong: [
        //   { required: true, message: '上课时间不能为空', trigger: 'blur' }
        // ],
      },
      roomDialog: false,
      seleTeaData: [],
      lessRoom: [],
      seleAssisData: [],
      clashData: [],
      timeDurationSelect: '',
    }
  },
  methods: {
    plusObject () {
      let weekOptionsDefault = [
        {
          title: '周一',
          choose: false,
        }, {
          title: '周二',
          choose: false,
        }, {
          title: '周三',
          choose: false,
        }, {
          title: '周四',
          choose: false,
        }, {
          title: '周五',
          choose: false,
        }, {
          title: '周六',
          choose: false,
        }, {
          title: '周日',
          choose: false,
        },
      ]
      let thembObject = {
        weekOptions: [],
        lessonTime: '',
      }
      weekOptionsDefault.forEach(item => {
        thembObject.weekOptions.push(item)
      })

      this.weekArray.push(thembObject)
    },
    removeObject (index) {
      this.weekArray.splice(index, 1)
    },
    weekClick (parentIndex, index) {
      this.weekArray[parentIndex].weekOptions[index].choose = !this.weekArray[parentIndex].weekOptions[index].choose
    },
    startTimeChange () {
      this.commonTime = ''
    },
    commonTimeChange (command) {
      if (this.formData.beginDate !== undefined) {
        console.log(this.commonTime.split('~')[0])
        this.formData.beginDate = this.formData.beginDate.substring(0, 10) + ' ' + command.startTime + ':00'
        this.formData.endTime = this.formData.beginDate.substring(0, 10) + ' ' + command.endTime + ':00'
        // this.formData.lessonLong = ((new Date(this.formData.endTime)).getTime() - (new Date(this.formData.beginDate)).getTime()) / 60 / 1000

        let flag = false
        // this.timeDurationOptions.forEach((item) => {
        //   if (item.value === parseInt(this.formData.lessonLong)) {
        //     this.timeDurationSelect = this.formData.lessonLong
        //     flag = true
        //   }
        // })
        if (!flag) {
          this.timeDurationSelect = ''
        }
      }
    },

    closeCommonTimeDialog () {
      this.showCommomTime = false
      this.commonTime = ''
      this.listTeacherLevel()
      this.$forceUpdate()
    },

    gotoAddCommenTime () {
      this.showCommomTime = true
    },
    timeDurationhandleChange () {
      this.commonTime = ''
      let flag = false
      // this.timeDurationOptions.forEach((item) => {
      //   if (item.value === parseInt(this.formData.lessonLong)) {
      //     this.timeDurationSelect = this.formData.lessonLong
      //     flag = true
      //   }
      // })
      if (!flag) {
        this.timeDurationSelect = ''
      }
    },
    timeDurationSelectChange (command) {
      this.commonTime = ''
      this.timeDurationSelect = command
      // this.formData.lessonLong = this.timeDurationSelect
    },

    openDialog () {
      this.weekArray = []
      this.plusObject()
      this.formData = {
        hour: '00',
        minute: '00',
        weekSet: [],
      }
      this.timeDurationSelect = ''
      this.lessRoom = []
      this.seleTeaData = []
      this.seleAssisData = []
    },

    close () {
      this.$emit('close')
    },

    forceUpdate () {
      this.$forceUpdate()
    },

    teacherData (data) {
      this.teacherIds = data
      let teacherIdsArray = []
      this.teacherIds.map(item => {
        teacherIdsArray.push(item.userId)
      })
      this.formData.teacherIds = teacherIdsArray
    },

    changeStrToMinutes (str) {
      var arrminutes = str.split(':')
      if (arrminutes.length === 2) {
        var minutes = parseInt(arrminutes[0]) * 60 + parseInt(arrminutes[1])
        return minutes
      } else {
        return 0
      }
    },

    confirm () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          // if(this.formData.lessonLong === 0) {
          //   window.$msg('上课时长不能为0',2)
          //   return false
          // }
          this.initNodeList()
          this.weekArray.forEach((item, index) => {
            item.weekOptions.forEach(value => {
              switch (value.title) {
                case '周一':
                  if (value.choose) {
                    console.log('index-------' + index)
                    if (this.nodeList[0].times === '') {
                      this.nodeList[0].times = item.lessonTime
                    } else {
                      this.nodeList[0].times = this.nodeList[0].times + ',' + item.lessonTime
                    }
                  }
                  break;
                case '周二':
                  if (value.choose) {
                    if (this.nodeList[1].times === '') {
                      this.nodeList[1].times = item.lessonTime
                    } else {
                      this.nodeList[1].times = this.nodeList[1].times + ',' + item.lessonTime
                    }
                  }
                  break;
                case '周三':
                  if (value.choose) {
                    if (this.nodeList[2].times === '') {
                      this.nodeList[2].times = item.lessonTime
                    } else {
                      this.nodeList[2].times = this.nodeList[2].times + ',' + item.lessonTime
                    }
                  }
                  break;
                case '周四':
                  if (value.choose) {
                    if (this.nodeList[3].times === '') {
                      this.nodeList[3].times = item.lessonTime
                    } else {
                      this.nodeList[3].times = this.nodeList[3].times + ',' + item.lessonTime
                    }
                  }
                  break;
                case '周五':
                  if (value.choose) {
                    if (this.nodeList[4].times === '') {
                      this.nodeList[4].times = item.lessonTime
                    } else {
                      this.nodeList[4].times = this.nodeList[4].times + ',' + item.lessonTime
                    }
                  }
                  break;
                case '周六':
                  if (value.choose) {
                    if (this.nodeList[5].times === '') {
                      this.nodeList[5].times = item.lessonTime
                    } else {
                      this.nodeList[5].times = this.nodeList[5].times + ',' + item.lessonTime
                    }
                  }
                  break;
                case '周日':
                  if (value.choose) {
                    if (this.nodeList[6].times === '') {
                      this.nodeList[6].times = item.lessonTime
                    } else {
                      this.nodeList[6].times = this.nodeList[6].times + ',' + item.lessonTime
                    }
                  }
                  break;
              }
            })
          })
          let nodeThembList = []
          this.nodeList.forEach(item => {
            if(item.times !== '') {
              nodeThembList.push(item)
            }
          })
          let confirmData = {
            classId: this.$route.query.classID,
            beginDate: this.formData.beginDate,
            nodeList: nodeThembList,
          }
          console.log(JSON.stringify(confirmData))
          addBat({
            ...confirmData
          }).then(res => {
            if (res.state === 'success') {
              this.close()
              this.$emit('getClassMessage')
            }
          })
        }
      })
    },
    initNodeList() {
      this.nodeList = []
      let week1 = { week: 'week1', times: '' }
      let week2 = { week: 'week2', times: '' }
      let week3 = { week: 'week3', times: '' }
      let week4 = { week: 'week4', times: '' }
      let week5 = { week: 'week5', times: '' }
      let week6 = { week: 'week6', times: '' }
      let week7 = { week: 'week7', times: '' }
      this.nodeList.push(week1)
      this.nodeList.push(week2)
      this.nodeList.push(week3)
      this.nodeList.push(week4)
      this.nodeList.push(week5)
      this.nodeList.push(week6)
      this.nodeList.push(week7)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";

.margintop10{
  margin-top: 10px;
}

.plus {
  font-size: 26px;
  margin-top: 3px;
  cursor: pointer
}

/deep/ .parentClass {
  padding: 0px 2px;
  border: #c3c3c3 solid 1px;
  border-radius: 6px;
  display: flex;

  .itemweek {
    flex: 1;
    display: flex;
    justify-content: center;
    color: #000000;
    background-color: #FFFFFF;
    padding: 0px 2px;
    cursor: pointer;
  }

  .itemweekchoose {
    flex: 1;
    display: flex;
    margin: 0px 2px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    justify-content: center;
    color: #FFFFFF;
    background-color: #f5c319;
    cursor: pointer;
  }
}
</style>
