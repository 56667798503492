<template>
  <div>
    <class-info v-if="type === 'Info'" :classInfo="classInfo" :pageFrom="pageFrom" @updateView="updateView"></class-info>
    <class-lesson v-else-if="type === 'Lesson'" :classInfo="classInfo" :pageFrom="pageFrom"></class-lesson>
  </div>
</template>

<script>
import ClassInfo from './class-info'
import ClassLesson from './class-lesson'
export default {
  props: {
    type: {
      default: 'Info',
      type: String
    },
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    },
    classStudent: {
      default: () => {
        return []
      },
      type: Array
    },
    pageFrom:{
      default: '',
      type: String
    },
    scrollTopTable: {
      default: 0,
      type: Number
    }
  },
  components: {
    ClassInfo,
    ClassLesson,
  },
  data () {
    return {}
  },
  methods: {
    updateView () {
      this.$emit('getClassTeacherInfoById')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
