<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="LessonDialogTitle"></span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="开始时间：" prop="start_time">
        <el-date-picker
          style="float: left"
          v-model="formData.start_time"
          type="datetime"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="startTimeChange"
          placeholder="选择开始上课时间">
        </el-date-picker>
        <el-row style="float: left" v-if="formData.start_time">
          <el-dropdown trigger="click" style="margin-left: 10px;"   v-if="commonTimeOptions.length !== 0" v-model="commonTime"  @command="commonTimeChange">
            <span class="el-dropdown-link">
              常用时间段<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="item" :key="index"  v-for="(item,index) in commonTimeOptions">
                <span style="float: left">{{ item.startTime }}~</span>
                <span style="float: left">{{ item.endTime }}</span>
                <span class="el-dropdown-link" style="float: right;margin-left: 15px" @click.stop="deleteCommonTime(item)">删除</span>
              </el-dropdown-item>
              <el-button style="margin: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button v-else style="margin-left: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>
        </el-row>
      </el-form-item>

      <el-form-item label="上课时长：" prop="duration">
        <el-input-number style="width: 20%;" disabled :precision="0" v-model="formData.duration" :min="0" :controls="false" @change="timeDurationhandleChange"></el-input-number>
        <span style="margin-left: 10px;margin-right: 30px;">分钟</span>
<!--        <el-dropdown trigger="click" style="margin-left: 10px;"   v-model="timeDurationSelect"  @command="timeDurationSelectChange">-->
<!--            <span class="el-dropdown-link">-->
<!--              常用时长<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--            </span>-->
<!--          <el-dropdown-menu slot="dropdown">-->
<!--            <el-dropdown-item :command="item.value" :key="index"   v-for="(item,index) in timeDurationOptions">-->
<!--              <span style="float: left">{{ item.label}}</span>-->
<!--            </el-dropdown-item>-->
<!--          </el-dropdown-menu>-->
<!--        </el-dropdown>-->
      </el-form-item>
      <el-form-item label="结束时间：">
        <el-input style="width: 30%;" v-model="formData.endTime" :min="0" disabled></el-input>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-button type="primary" @click="saveRoleForm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { formatTate } from '@/utils/formatDate'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    },
    addlessonData: {
      default: () => {
        return {}
      },
      type: Object
    },
  },
  components: {},
  data () {
    return {
      LessonDialogTitle: '',
      roomIds: [],
      lessRoom: [],
      formData: {
        teacherIds: [],
        assistantIds: [],
        studentIds: [],
        contestIds: [],
        hour: '00',
        minute: '00'
      },
      options: [],
      teacherDialog: false,
      assistantDialog: false,
      // userStudentDialog: false,
      lessonStudentDialog:false,
      roomDialog: false,
      contestDialog: false,
      timeDialog: false,
      roomTimeDialog: false,
      rules: {
        start_time: [
          { required: true, message: '请选择上课时间段', trigger: 'blur' }
        ],
        courseUnitId: [
          { required: true, message: '请选择关联课节', trigger: 'blur' }
        ],
        duration: [
          { required: true, message: '上课时长不能为空', trigger: 'blur' }
        ],
        teacherIds: [
          { required: true, message: '上课讲师不能为空', trigger: 'blur' }
        ]
      },
      schoolArray: [],
      teacherIds: [], // 已选老师
      assistantIds: [], // 已选助教
      studentIds: [], // 已选学生
      contestIds: [], // 已选题库
      stuList: [], // 排课学员,
      seleTeaData: [], // 排课老师
      seleAssisData: [], // 排课助教
      seleStuData: [],
      clashDialog: false,
      clashData: [],
      lesson_id: '',
      timeDurationSelect:'',
      timeDurationOptions: [ // 时长
        {
          value: 30,
          label: '30分钟'
        },
        {
          value: 45,
          label: '45分钟'
        },
        {
          value: 60,
          label: '60分钟'
        },
        {
          value: 90,
          label: '90分钟'
        },
        {
          value: 120,
          label: '120分钟'
        },
        {
          value: 180,
          label: '180分钟'
        }
      ],
      commonTimeOptions:[],
      showCommomTime:false,
      commonTime:''
    }
  },
  methods: {
    commonTimeChange(command) {
      if(this.formData.start_time !== undefined){
        console.log(this.commonTime.split('~')[0])
        this.formData.start_time = this.formData.start_time.substring(0,10)+' '+command.startTime+':00'
        this.formData.endTime = this.formData.start_time.substring(0,10)+' '+command.endTime+':00'
        this.formData.duration = ((new Date(this.formData.endTime)).getTime() - (new Date(this.formData.start_time)).getTime()) / 60 / 1000

        let flag = false
        this.timeDurationOptions.forEach((item) => {
          if (item.value === parseInt(this.formData.duration)) {
            this.timeDurationSelect = this.formData.duration
            flag = true
          }
        })
        if(!flag) {
          this.timeDurationSelect = ''
        }
      }
    },

    deleteCommonTime(row) {
      this.$confirm('确定删除此常用时间段吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteUserPeriod(row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    gotoAddCommenTime() {
      this.showCommomTime = true
    },
    startTimeChange() {
      this.commonTime = ''
      let oldTime = 0
      if(this.formData.duration !== undefined) {
        oldTime = (new Date(this.formData.start_time)).getTime() + parseInt(this.formData.duration) * 60 * 1000;
      }else {
        oldTime = (new Date(this.formData.start_time)).getTime()
      }
      if(this.formData.start_time !== null) {
        this.formData.endTime = formatTate(oldTime);
      }else {
        this.formData.endTime = undefined
      }
      this.$forceUpdate()
    },
    timeDurationhandleChange() {
      this.commonTime = ''
      let flag = false
      this.timeDurationOptions.forEach((item) => {
        if (item.value === parseInt(this.formData.duration)) {
          this.timeDurationSelect = this.formData.duration
          flag = true
        }
      })
      if(!flag) {
        this.timeDurationSelect = ''
      }
      if(this.formData.start_time !== undefined) {
        var oldTime = (new Date(this.formData.start_time)).getTime() + parseInt(this.formData.duration) * 60 * 1000;
        this.formData.endTime = formatTate(oldTime);
        this.$forceUpdate()
      }
    },
    timeDurationSelectChange(command) {
      this.commonTime = ''
      this.timeDurationSelect = command
      this.formData.duration = this.timeDurationSelect

      if(this.formData.start_time !== undefined && this.formData.start_time !== null) {
        if( this.formData.duration !== '') {
          var oldTime = (new Date(this.formData.start_time)).getTime() + parseInt(this.formData.duration) * 60 * 1000;
          this.formData.endTime = formatTate(oldTime);
          this.$forceUpdate()
        }else {
          this.formData.endTime = this.formData.start_time
        }
      }else{
        this.formData.endTime = ''
      }
    },
    checkRoomTime () {
      this.roomTimeDialog = true
    },

    removeRoom (tag) {
      this.roomIds = []
    },

    removeTeacher (tag) {
      this.teacherIds = []
      this.seleTeaData = []
    },

    removeAssistant (tag) {
      this.assistantIds.forEach((ele, idx)=> {
        if (ele.id === tag) {
          this.assistantIds.splice(idx, 1)
        }
      })
    },

    forceUpdate () {
      this.$forceUpdate()
    },
    selectChange (id) {
      console.log(id)
      // this.formData.courseUnitId = id;
    },
    openDialog () {
      this.formData = {
        hour: '00',
        minute: '00'
      }
      this.options = []
      this.commonTime = ""
      this.seleAssisData = []
      this.seleTeaData = []
      this.lessRoom = []
      // console.log("this.addlessonData"+JSON.stringify(this.addlessonData));
      if(this.$route.query.classID === undefined) {
        this.class_id = this.classInfo.classId
      }else {
        this.class_id = this.$route.query.classID
      }

      if (this.classInfo.classroomId && !this.addlessonData) {
        this.lessRoom.push({
          id: this.classInfo.classroomId,
          title: this.classInfo.classroomTitle
        })
        this.roomIds = this.lessRoom
        let roomIdsArray = []
        this.roomIds.map(item => {
          roomIdsArray.push(item.id)
        })
        this.formData.roomIds = roomIdsArray
      } else if (this.addlessonData && this.addlessonData.classroom_id) {
        this.lessRoom.push({
          id: this.addlessonData.classroom_id,
          title: this.addlessonData.classroomTitle
        })
        this.roomIds = this.lessRoom
        let roomIdsArray = []
        this.roomIds.map(item => {
          roomIdsArray.push(item.id)
        })
        this.formData.roomIds = roomIdsArray
      } else {
        this.formData.roomIds = []
      }

      // 上课助教
      let AssIds = []
      let AsName = []
      if (this.classInfo.assistantUserIds && this.classInfo.assistant && !this.addlessonData) {
        AssIds = this.classInfo.assistantUserIds.split(',')
        AsName = this.classInfo.assistant.split(',')
      } else if (this.addlessonData && this.addlessonData.assistantUserIds) {
        AssIds = this.addlessonData.assistantUserIds.split(',')
        AsName = this.addlessonData.assistant.split(',')
      } else {}

      if (AssIds.length !== 0 && AsName.length !== 0) {
        this.seleAssisData = []
        AssIds.forEach(item => {
          this.seleAssisData.push({
            userId: item,
          })
        })
        AsName.forEach((item, index) => {
          this.seleAssisData.forEach((ele, idx) => {
            if (index === idx) {
              ele.name = item
            }
          })
        })

        let AssArray = []
        this.seleAssisData.map(item => {
          AssArray.push(item.userId)
        })
        this.formData.assistantIds = AssArray
      } else {
        this.formData.assistantIds = []
      }

      // 上课老师
      if (this.classInfo.zjUserIds && this.classInfo.zjName && !this.addlessonData) {
        this.seleTeaData = []
        this.seleTeaData.push({
          userId: this.classInfo.zjUserIds,
          name: this.classInfo.zjName,
        })
        let teacherArray = []
        this.seleTeaData.map(item => {
          teacherArray.push(item.userId)
        })
        this.formData.teacherIds = teacherArray
      } else if (this.addlessonData && this.addlessonData.zjUserIds) {
        this.seleTeaData = []
        this.seleTeaData.push({
          userId: this.addlessonData.zjUserIds,
          name: this.addlessonData.zjName,
        })
        let teacherArray = []
        this.seleTeaData.map(item => {
          teacherArray.push(item.userId)
        })
        this.formData.teacherIds = teacherArray
      } else {
        this.formData.teacherIds = []
      }

      // if(this.addlessonData.class_id) {
      if (!this.addlessonData) {
        this.LessonDialogTitle = '新增排课'
        this.seleStuData = []
        this.lesson_id = ''
        this.timeDurationSelect = ''
        // 班级管理页面
      } else {
        this.LessonDialogTitle = '编辑排课'
        this.formData = Object.assign({}, this.addlessonData)
        this.formData.start_time = this.addlessonData.start_time
        this.formData.duration = ((new Date(this.addlessonData.end_time)).getTime() - (new Date(this.addlessonData.start_time)).getTime()) / 60 / 1000

        let flag = false
        this.timeDurationOptions.forEach((item) => {
          if (item.value === parseInt(this.formData.duration)) {
            this.timeDurationSelect = this.formData.duration
            flag = true
          }
        })
        if(!flag) {
          this.timeDurationSelect = ''
        }
        this.formData.endTime = this.formData.end_time
        this.lesson_id = this.addlessonData.id
      }
    },



    subtract_hour (a, b) {
      let result = Math.abs((Number(a) - Number(b)))
      if (result < 10) {
        result = '0' + result
      }
      return result
    },

    subtract_minute (a, b) {
      let result = Math.abs((Number(a) - Number(b)))
      if (result < 10) {
        result = '0' + result
      }
      return result
    },
    // 关闭
    close () {
      this.$emit('close')
    },

    // 保存
    saveRoleForm () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          console.log(this.formData.duration)
          if(this.formData.duration === 0) {
            window.$msg('上课时长不能为0',2)
            return false
          }
          if (!this.addlessonData) {
            this.$emit('addLessonForm', this.formData)
          } else {
            this.$emit('editLessonForm', this.formData)
          }
        }
      })
    },

    // 查看老师剩余时间
    checkTime () {
      this.timeDialog = true
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
.duration {
  width: 35px;
  border: 0 !important;
  /deep/ .el-input__inner {
    width: 35px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-radius: 0;
    padding: 0 9px;
    // height: 15px;
    // line-height: 15px;
  }
}
.colon {
  display: inline-block;
  margin: 0 8px;
}
</style>
